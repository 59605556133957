import "./App.css";

import {
  ActionIcon,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  Text,
  Title,
} from "@mantine/core";
import {
  At,
  BrandGithub,
  BrandLinkedin,
  BrandMedium,
  CurrentLocation,
} from "tabler-icons-react";

import { Tilt } from "./Tilt";

function App() {
  return (
    <Container size={"lg"} sx={{ minHeight: "100vh" }}>
      <Grid gutter={30}>
        <Grid.Col md={12} lg={6}>
          <Tilt>
            <Image
              radius="md"
              src="/masoud-banimahd.jpg"
              alt="Masoud Banimahd"
            />
          </Tilt>
        </Grid.Col>
        <Grid.Col md={12} lg={6}>
          <Flex justify={"center"} sx={{ height: "100%" }} direction={"column"}>
            <Title
              order={1}
              sx={{
                fontSize: 50,
                marginBottom: 20,
                fontFamily: "Comfortaa",
                color: "#4e4e4e",
              }}
            >
              Masoud Banimahd
            </Title>
            <Text
              sx={{
                fontFamily: "Comfortaa",
                textAlign: "justify",
                textJustify: "inter-word",
                marginBottom: 20,
              }}
            >
              I am a software engineer with more than 9 years of experience in
              the field. My expertise covers every aspect of the software
              development life cycle, from project analysis to design,
              development, testing, and documentation.
              <br />
              <br />I possess a solid background in C# (.Net Core) and
              JavaScript/TypeScript (Node.js), as well as practical experience
              working with React.js, Next.js, Vue.js, and multiple database
              engines like MySQL, MongoDB, and Redis. I am passionate about
              constantly improving my skills and embracing new challenges in the
              industry.
            </Text>
            <Group>
              <Button
                leftIcon={<CurrentLocation />}
                variant="default"
                size="xs"
                sx={{ border: 0, background: "transparent" }}
              >
                Gothenburg, Sweden
              </Button>
            </Group>
            <Divider my={20} />
            <Group>
              <ActionIcon
                component="a"
                href="mailto:hej@masoudb.com"
                size={"lg"}
              >
                <At size={60} strokeWidth={2} color={"#ff9900"} />
              </ActionIcon>
              <ActionIcon
                component="a"
                href="https://www.linkedin.com/in/masoudbanimahd/"
                target={"_blank"}
                size={"lg"}
              >
                <BrandLinkedin size={60} strokeWidth={2} color={"#397aff"} />
              </ActionIcon>
              <ActionIcon
                component="a"
                href="https://masoudx.medium.com"
                target={"_blank"}
                size={"lg"}
              >
                <BrandMedium size={48} strokeWidth={2} color={"#898989"} />
              </ActionIcon>
              <ActionIcon
                component="a"
                href="https://github.com/dev3mike"
                target={"_blank"}
                size={"lg"}
              >
                <BrandGithub size={48} strokeWidth={2} color={"#ffffff"} />
              </ActionIcon>
            </Group>
          </Flex>
        </Grid.Col>
      </Grid>
    </Container>
  );
}

export default App;
