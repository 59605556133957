import { Box } from '@mantine/core';
import { PropsWithChildren, useEffect, useState } from 'react';

interface MousePos {
    x: number;
    y: number;
}


export function Tilt(props: PropsWithChildren){
    const [mousePos, setMousePos] = useState<MousePos>({x: 0, y: 0} as MousePos);
    const middleX = window.innerWidth / 2;
    const middleY = window.innerHeight / 2;
    const offsetX = ((mousePos.x - middleX) / middleX) * 10;
    const offsetY = ((mousePos.y - middleY) / middleY) * 10;

    useEffect(() => {
      const handleMouseMove = (event: any) => {
        setMousePos({ x: event.clientX, y: event.clientY });
      };
  
      window.addEventListener('mousemove', handleMouseMove);
  
      return () => {
        window.removeEventListener(
          'mousemove',
          handleMouseMove
        );
      };
    }, []);

    return <Box sx={{
        boxShadow: '0 0 920px #0000008c',
        marginTop: 20,
        transformStyle: 'preserve-3d',
        transform: `perspective(5000px) rotateY(${offsetX}deg) rotateX(${-1 * offsetY}deg)`
    }}>{props.children}</Box>
}